import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Box, Text } from "./../../components/elements";
import Pagination from "react-js-pagination";
import { CardLayout } from "./../../components/cards";
import Modal from "react-bootstrap/Modal";
import { PayPalButton } from "react-paypal-button-v2";
export default function Invoice() {
  const { token, userData } = useSelector((state) => state.counter);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState([]);
  const [planData, setPlanData] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [selectedID, setSelectedID] = useState("");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    fetchData(1);
    fetchPlanData();
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      practitioner: userData._id,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/payments/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  async function fetchPlanData() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/dasborad/admin?practitioner=" + userData._id, options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setPlanData(Response.data.result.planData);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Invoice"}>
              <li className="mc-breadcrumb-item">
                <b>Current Plan:</b>&nbsp;{planData.subscription && planData.subscription.title}
              </li>
              <li className="mc-breadcrumb-item">
                <b>Status:</b>&nbsp;
                {planData && planData.planActive == 0 && (
                  <>{planData.planStartTimestamp == 0 && planData.planActive == 0 && moment().format("X") > planData.trialExpTimestamp ? <span className="p-1 rounded bg-warning text-white">Trial Expired</span> : <span className="p-1 rounded bg-success text-white">In Trial</span>}</>
                )}
                {planData && planData.planActive == 1 && <>{planData && planData.planActive == 1 && moment().format("X") > planData.planExpTimestamp ? <span className="p-1 rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>}
              </li>
              <li className="mc-breadcrumb-item">
                <b>Exp. Date:</b>&nbsp; {planData.planActive == 0 && planData.planStartTimestamp == 0 && moment.unix(planData.trialExpTimestamp).format("YYYY-MM-DD")}
                {planData.planActive == 1 && planData.trialStartTimestamp == 0 && moment.unix(planData.planExpTimestamp).format("YYYY-MM-DD")}
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Box className="mc-table-responsive">
              <Table className="mc-table">
                <Thead className="mc-table-head primary">
                  <Tr>
                    <Th># ID</Th>
                    <Th>Created Date</Th>
                    <Th>Plan</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                  {data?.length > 0 &&
                    data.map((item, key) => (
                      <Tr key={key}>
                        <Td>{item.invoiceId}</Td>
                        <Td>{moment(item.createdAt).format("DD/MM/YYYY")}</Td>
                        <Td>
                          {item.subscription.title}-{item.term}
                        </Td>
                        <Td>${item.amount}</Td>
                        <Td>{item.paid == 0 ? <span class="bg-danger p-1 rounded text-white">Pending</span> : <span class="bg-success p-1 rounded text-white">Paid</span>}</Td>
                        <Td>
                          {item.paid == 0 ? (
                            <a
                              class="btn bg-primary text-white"
                              onClick={() => {
                                setSelectedID(item._id);
                                setShow(true);
                                setAmount(item.amount);
                              }}
                            >
                              <span>Pay Now</span>
                            </a>
                          ) : (
                            "-"
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            <Box className="mc-paginate">
              <Text className="mc-paginate-title">
                Total <b>{totalRecod}</b> Results Found
              </Text>
              <nav aria-label="Page navigation example">
                {totalRecod > perPage ? (
                  <Pagination
                    prevPageText="prev"
                    nextPageText="next"
                    firstPageText="first"
                    lastPageText="last"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalRecod}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                ) : (
                  ""
                )}
              </nav>
            </Box>
          </CardLayout>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Make payment ${amount}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayPalButton
            amount={amount}
            clientId={process.env.REACT_APP_PAYPAL_CLIENT}
            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
            onSuccess={(details, data) => {
              toast.success("Transaction completed");
              setShow(false);
              // OPTIONAL: Call your server to save the transaction
              return fetch(process.env.REACT_APP_API_URL + "app/payments/create-paypal-request?txn=" + data.orderID + "&id=" + selectedID, {
                method: "GET",
                headers: { authorization: token, "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
              });
            }}
          />
        </Modal.Body>
      </Modal>
    </PageLayout>
  );
}
