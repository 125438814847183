import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Anchor, Button, Icon } from "./../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Box, Text } from "./../../components/elements";
import Modal from "react-bootstrap/Modal";
import Wallet from "../blocks/Wallet";
import ATSSystemAdd from "../blocks/atsSystemAdd";
import ToggleButton from "react-toggle-button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Report from "../blocks/Report";

import { setReportData } from "../../actions";
import { useDispatch } from "react-redux";

export default function ProductList() {
  const dispatch = useDispatch();
  const { userData, token, saveIndicator, reportData } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [showSwal2, setShowSwal2] = useState(false);

  const [show, setShow] = useState(false);

  const [atsShow, setAtsShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState("");

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [appointmentDate, setAppointmentDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [payment, setPayment] = useState("");

  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");
  const [detail, setDetail] = useState({});

  const [initial, setInitial] = useState(false);
  const [male, setMale] = useState(false);

  useEffect(() => {
    fetchPatient();
    fetchData(1);
  }, []);

  useEffect(() => {
    if (reportData && reportData.openModal) {
      setSelectedAppointment(reportData.selectedAppointment);
      setInitial(reportData.initial);
      setMale(reportData.male);
      setAtsShow(true);
    }
  }, [reportData]);

  useEffect(() => {
    if (!atsShow) {
      dispatch(
        setReportData({
          openModal: false,
          selectedAppointment: id,
          initial: false,
          male: male,
          tab: "AnteriorView",
        })
      );
    }
  }, [atsShow]);

  async function fetchData(pageNumber) {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      patient: id,
      startdate: filterStartDate,
      enddate: filterEndDate,
      practitioner: userData._id,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/user-appointment?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function fetchPatient() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const options = {
      headers: { authorization: token },
    };

    const params = {
      id: id,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/patient/detail?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setDetail(Response.data.result);
        if (Response.data.result.gender == "M") {
          setMale(true);
        } else {
          setMale(false);
        }
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchPatient(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/appointment/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchPatient(1);
          fetchData(1);
          setActivePage(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  async function handleComplete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/appointment/complete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchPatient(1);
          fetchData(1);
          setActivePage(1);
          setShowSwal2(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  async function handleBooking() {
    if (!appointmentDate && appointmentDate == "") {
      toast.error("Enter appointment date");
      return;
    }

    if (!startTime && startTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!endTime && endTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!payment && payment == "") {
      toast.error("Enter appointment payment");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const urlString = window.location.search;
      const urlParams = new URLSearchParams(urlString);
      const id = urlParams.get("id");

      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/book",
        {
          appointmentDate: appointmentDate,
          startTime: startTime,
          endTime: endTime,
          payment: payment,
          practitioner: userData._id,
          patient: id,
        },
        options
      );
      if (Response.data.status == "success") {
        setAppointmentDate("");
        setStartTime("");
        setEndTime("");
        setPayment("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchPatient(1);
        fetchData(1);
        setActivePage(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        //setShow(false);
      }
    } else {
      const urlString = window.location.search;
      const urlParams = new URLSearchParams(urlString);
      const userid = urlParams.get("id");

      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/update/" + id,
        {
          appointmentDate: appointmentDate,
          startTime: startTime,
          endTime: endTime,
          payment: payment,
          practitioner: userData._id,
          patient: userid,
        },
        options
      );
      if (Response.data.status == "success") {
        setAppointmentDate("");
        setStartTime("");
        setEndTime("");
        setPayment("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchPatient(1);
        fetchData(1);
        setActivePage(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        // setShow(false);
      }
    }
  }

  function timestampToHHMM(timestamp) {
    const hours = timestamp.getHours().toString().padStart(2, "0");
    const minutes = timestamp.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <h4 className="mb-4">Details of {detail.name}</h4>
            <Tabs defaultActiveKey="details" className="mb-3">
              <Tab eventKey="details" title="Details">
                <div className="row">
                  <div className="col-md-12 patient_details">
                    <Tabs defaultActiveKey="Personal" className="mb-3" justify variant="pills">
                      <Tab eventKey="Personal" title="Personal">
                        <Row>
                          <Col xl={4}>
                            <LabelField label="name" type="text" fieldSize="w-100 h-md" value={detail.name} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="email  " type="email" fieldSize="w-100 h-md" value={detail.email} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="phone  " type="text" fieldSize="w-100 h-md" value={detail.phone} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <div class="mc-label-field-group label-col">
                              <label class="mc-label-field-title">Gender </label>
                              <select class="mc-label-field-input w-100 h-md" value={detail.gender} readOnly={true} disable={true}>
                                <option value={""}> Select </option>
                                <option value={"M"}> Male </option>
                                <option value={"F"}> Female </option>
                                <option value={"O"}> Other </option>
                              </select>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <LabelField label="date of birth " type="date" fieldSize="w-100 h-md" value={detail.dob} readOnly={true} disable={true} />
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="Emergency" title="Emergency">
                        <Row>
                          <Col xl={4}>
                            <LabelField label="Emergency contact name" type="text" fieldSize="w-100 h-md" value={detail.emergency_contact_name} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Emergency contact phone" type="number" fieldSize="w-100 h-md" value={detail.emergency_contact_phone} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Emergency contact relationship with you" type="text" fieldSize="w-100 h-md" value={detail.emergency_contact_relation} readOnly={true} disable={true} />
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="Primary" title="Primary Care">
                        <Row>
                          <Col xl={4}>
                            <LabelField label="Physician's Name" type="text" fieldSize="w-100 h-md" value={detail.primary_care_physician_name} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Physician's Phone" type="number" fieldSize="w-100 h-md" value={detail.primary_care_physician_phone} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Physician's Email" type="email" fieldSize="w-100 h-md" value={detail.primary_care_physician_email} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={12}>
                            <div class="mc-label-field-group label-col">
                              <label class="mc-label-field-title"> Physician's Adddress</label>
                              <textarea placeholder="Type here..." class="mc-label-field-input w-100 h-md" value={detail.primary_care_physician_address} readOnly={true} disable={true}></textarea>
                            </div>
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="Record" title="Health Record">
                        <Row className="mt-3">
                          <Col xl={9}>Do you have past or current injuries?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.pastInjuries} />
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col xl={9}>Do you have difficult laying on your front, back, or side?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.difficultLaying} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you have an allergy to oils, lotions, or ointments?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.allergy} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you wear contact lenses?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.contactLenses} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you wear dentures?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.dentures} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you wear hearing aids?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.hearingAids} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you currently participate in resistance (weight) training routine?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.weightTraining} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you currently participate in a stretch training routine?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.stretchTraining} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={12}>I am participating in the following activities to help me feel better?</Col>
                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.how_i_see_this} />
                              <label class="form-check-label">How I see things</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.how_i_feel} />
                              <label class="form-check-label">How I feel</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.what_i_belive} />
                              <label class="form-check-label">What I Believe</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.my_relationships} />
                              <label class="form-check-label">My Relationships</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.event_attending} />
                              <label class="form-check-label">Event attending</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.resources} />
                              <label class="form-check-label">Resources</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.related_information} />
                              <label class="form-check-label">Related Information</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.time} />
                              <label class="form-check-label">Time</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.work} />
                              <label class="form-check-label">Work</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.other} />
                              <label class="form-check-label">Other</label>
                            </div>
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={12}>What goals do you want to achieve through these sessions?</Col>
                          <Col xl={12}>
                            <textarea class="mc-label-field-input w-100 h-md" value={detail.goal} readOnly={true} disable={true}></textarea>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col xl={12}>Is there any other significant information about your physical or mental health that your D- Practitioner should know?</Col>
                          <Col xl={12}>
                            <textarea class="mc-label-field-input w-100 h-md" value={detail.otherInfo} readOnly={true} disable={true}></textarea>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="appointment" title="Appointment">
                <CardLayout className={"mt-3"}>
                  <Row>
                    <Col xl={4}>
                      <div className="form-group">
                        <label>Start date</label>
                        <input type="date" className="form-control" value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                      </div>
                    </Col>

                    <Col xl={4}>
                      <div className="form-group">
                        <label>End date</label>
                        <input type="date" className="form-control" value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                      </div>
                    </Col>

                    <Col xl={4}>
                      <br />
                      <Button
                        icon={"search"}
                        text={"Search"}
                        href={"#"}
                        className="mc-btn primary w-100"
                        onClick={() => {
                          setActivePage(1);
                          fetchData(1);
                        }}
                      />
                    </Col>

                    <Col xl={3}>
                      <Button icon={"today"} text={"Add appointment"} href={"#"} className="mc-btn primary w-100" onClick={() => setShow(true)} />
                    </Col>

                    <Col xl={3}>
                      {data?.length > 0 && data[0].isOpen && (
                        <Button
                          icon={"add"}
                          onClick={() => {
                            setAtsShow(true);
                            setSelectedAppointment(data[0]._id);
                            setInitial(true);
                          }}
                          className="mc-btn primary w-100"
                        >
                          Initial Assesment
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Box className="mc-table-responsive">
                    <Table className="mc-table">
                      <Thead className="mc-table-head primary">
                        <Tr>
                          <Th># ID</Th>
                          <Th>Date/Time</Th>
                          <Th>Payment</Th>
                          <Th>Complete</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody className="mc-table-body even">
                        {data?.length > 0 &&
                          data.map((item, key) => (
                            <Tr key={key}>
                              <Td> {item.uniqueId}</Td>
                              <Td>
                                {moment(item.appointmentDate).format("DD/MM/YYYY")} <br />
                                {moment.unix(item.startTime).format("hh:mm A")} - {moment.unix(item.endTime).format("hh:mm A")}
                              </Td>

                              <Td>{item.payment.replace("_", " ")}</Td>
                              <Td>
                                {item.isOpen ? <span className="bg-warning p-1 rounded">No</span> : <span className="bg-success p-1 rounded text-white">Yes</span>}
                                <br />
                                {item.isOpen && (
                                  <Button
                                    size="sm"
                                    className={"btn-link"}
                                    onClick={() => {
                                      setId(item._id);
                                      setShowSwal2(true);
                                    }}
                                  >
                                    <small>Mark Complete</small>
                                  </Button>
                                )}
                              </Td>
                              <Td>
                                <Box className="mc-table-action text-center">
                                  {item.isOpen && (
                                    <>
                                      <Button
                                        title="Add record"
                                        className="view p-2"
                                        onClick={() => {
                                          setAtsShow(true);
                                          setSelectedAppointment(item._id);
                                          setInitial(false);
                                        }}
                                      >
                                        Add
                                      </Button>

                                      <Button
                                        title="Edit record"
                                        className="edit p-2"
                                        onClick={() => {
                                          setMode("edit");
                                          setId(item._id);
                                          setAppointmentDate(item.appointmentDate);
                                          setStartTime(timestampToHHMM(new Date(item.startTime * 1000)));
                                          setEndTime(timestampToHHMM(new Date(item.startTime * 1000)));
                                          setPayment(item.payment);
                                          setShow(true);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </>
                                  )}

                                  <Button
                                    title="Delete"
                                    className="delete p-2"
                                    onClick={() => {
                                      setId(item._id);
                                      setShowSwal(true);
                                    }}
                                  >
                                    Dlete
                                  </Button>
                                </Box>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </Box>
                  <Box className="mc-paginate">
                    <Text className="mc-paginate-title">
                      Total <b>{totalRecod}</b> Results Found
                    </Text>
                    <nav aria-label="Page navigation example">
                      {totalRecod > perPage ? (
                        <Pagination
                          prevPageText="prev"
                          nextPageText="next"
                          firstPageText="first"
                          lastPageText="last"
                          activePage={activePage}
                          itemsCountPerPage={perPage}
                          totalItemsCount={totalRecod}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                          innerClass="pagination justify-content-center"
                        />
                      ) : (
                        ""
                      )}
                    </nav>
                  </Box>
                </CardLayout>
              </Tab>
              <Tab eventKey="wallet" title={" Wallet (Balance: " + detail.balance + " min.)"}>
                <Wallet />
              </Tab>
              <Tab eventKey="reports" title="Reports">
                <Report />
              </Tab>
            </Tabs>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} appointment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <LabelField label="appointment date" type="date" fieldSize="w-100 h-md" value={appointmentDate} onChange={(e) => setAppointmentDate(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="appointment start time" type="time" fieldSize="w-100 h-md" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="appointment end time" type="time" fieldSize="w-100 h-md" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">Payment</label>
                <select class="mc-label-field-input w-100 h-md" value={payment} onChange={(e) => setPayment(e.target.value)}>
                  <option value={""}> Select </option>
                  <option value={"wallet"}> Use wallet balance </option>
                  <option value={"prepaid_cash"}> Prepaid by cash </option>
                  <option value={"prepaid_card"}> Prepaid by card </option>
                  <option value={"pending"}> Not paid </option>
                </select>
              </div>
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleBooking()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Check & Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This data will be permanently deleted.
        </SweetAlert>
      )}

      <Modal show={atsShow} onHide={() => setAtsShow(false)} fullscreen={true}>
        <Modal.Header>
          <Modal.Title>
            Manage record{" "}
            {saveIndicator ? (
              <small className="p-1 bg-warning rounded" style={{ fontSize: 13 }}>
                Syncing Data. Please Wait...
              </small>
            ) : (
              ""
            )}
          </Modal.Title>
          <button type="button" class="btn btn-primary" onClick={() => setAtsShow(false)}>
            Submit & Close
          </button>
        </Modal.Header>
        <Modal.Body>
          <ATSSystemAdd selectedAppointment={selectedAppointment} initial={initial} male={male} />
        </Modal.Body>
      </Modal>

      {showSwal2 && (
        <SweetAlert warning showCancel confirmBtnText="Yes, mark complete!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleComplete} onCancel={() => setShowSwal2(false)}>
          This can not be revised.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
