import React from "react";
import { Row, Col } from "react-bootstrap";
import { LabelField } from "../../components/fields";
import { useState, useEffect } from "react";
import { Button } from "../../components/elements";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
export default function Alerts() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [slug, setSlug] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDescrption, setSiteDescrption] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [Saving, setSaving] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [terms, setTerms] = useState("");
  const [avalabel, setAvalabel] = useState(true);

  async function updateSettings() {
    if (!avalabel) {
      toast.error("Slug is not avalabel");
      return;
    }

    if (!slug && slug == "") {
      toast.error("Enter site slug");
      return;
    }

    if (!siteTitle && siteTitle == "") {
      toast.error("Enter site title");
      return;
    }

    if (!siteDescrption && siteDescrption == "") {
      toast.error("Enter site descrption");
      return;
    }

    if (!phone && phone == "") {
      toast.error("Enter phone number");
      return;
    }

    if (!email && email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }

    if (!address && address == "") {
      toast.error("Enter address");
      return;
    }
    setSaving(true);
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/practitioner-settings/update",
      {
        slug: slug,
        siteTitle: siteTitle,
        siteDescrption: siteDescrption,
        phone: phone,
        email: email,
        address: address,
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
        youtube: youtube,
        about: about,
        privacy: privacy,
        terms: terms,
        id: userData._id,
      },
      options
    );
    if (Response.data.status == "success") {
      setSaving(false);
      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (slug && slug.length > 3) {
      checkAvability(slug);
    }
  }, [slug]);

  async function checkAvability(newslug = "") {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/slug-availability?user=" + userData._id + "&slug=" + newslug, options);
    if (Response.data.status) {
      setAvalabel(false);
    } else {
      setAvalabel(true);
    }
  }

  async function fetchSettings() {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/practitioner-settings?user=" + userData._id, options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setSlug(Response.data.slug);
        setSiteTitle(Response.data.result.siteTitle);
        setSiteDescrption(Response.data.result.siteDesc);
        setPhone(Response.data.result.displayPhone);
        setEmail(Response.data.result.displayEmail);
        setAddress(Response.data.result.displayAddress);
        setFacebook(Response.data.result.facebook);
        setInstagram(Response.data.result.instagram);
        setTwitter(Response.data.result.twitter);
        setYoutube(Response.data.result.youtube);
        setAbout(Response.data.result.about);
        setPrivacy(Response.data.result.privacy);
        setTerms(Response.data.result.terms);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <>
      <Row className="mt-3">
        <Col xl={12} className="ps-xl-5">
          <Row>
            <Col xl={12}>
              <label>Website SLUG</label>
              <div class="input-group">
                <input placeholder="Enter only text" value={slug} onChange={(e) => setSlug(e.target.value)} class="form-control" />
                <span class="input-group-text" id="basic-addon2">
                  .atsdhst.com
                </span>
              </div>
              {slug && slug.length > 3 && <span>{avalabel ? <span className="text-success">Congratulations! The slug is avalabel for registration</span> : <span className="text-danger">Oops! The slug is not avalabel for registration</span>}</span>}
            </Col>

            <Col xl={12}>
              <LabelField label="site title" type="text" fieldSize="w-100 h-md" value={siteTitle} onChange={(e) => setSiteTitle(e.target.value)} />
            </Col>
            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Description</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={siteDescrption} onChange={(e) => setSiteDescrption(e.target.value)}></textarea>
              </div>
            </Col>
            <Col xl={6}>
              <LabelField label="phone number" type="text" fieldSize="w-100 h-md" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Col>
            <Col xl={6}>
              <LabelField label="email address" type="email" fieldSize="w-100 h-md" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Col>
            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> address</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={6}>
              <LabelField label="facebook" type="url" fieldSize="w-100 h-md" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
            </Col>
            <Col xl={6}>
              <LabelField label="instagram" type="url" fieldSize="w-100 h-md" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
            </Col>

            <Col xl={6}>
              <LabelField label="twitter" type="url" fieldSize="w-100 h-md" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
            </Col>

            <Col xl={6}>
              <LabelField label="youtube" type="url" fieldSize="w-100 h-md" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col text-left">
                <label className="mc-label-field-title"> About</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={about} onChange={(e) => setAbout(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Terms</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={terms} onChange={(e) => setTerms(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Privacy</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={privacy} onChange={(e) => setPrivacy(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <Button className="btn btn-primary w-100" type="button" disable={!Saving} onClick={() => updateSettings()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save</>}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
