import React, { useEffect, useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MenAtsFront from "./menAtsFront";
import MenAtsBack from "./menAtsBack";
import WomenAtsFront from "./womenAtsFront";
import WomenAtsBack from "./womenAtsBack";
import MenAtsFrontMuscle from "./menAtsFrontMuscle";
import MenAtsBackMuscle from "./menAtsBackMuscle";

import WomenAtsFrontMuscle from "./womenAtsFrontMuscle";
import WomenAtsBackMuscle from "./womenAtsBackMuscle";

import InternalCavity from "./internalCavity";
import Diaphragm from "./Diaphragm";
import LeftHand from "./LeftHand";
import RightHand from "./RightHand";
import LeftFoot from "./LeftFoot";
import RightFoot from "./RightFoot";
import { useSelector } from "react-redux";
import { setChatData } from "../../actions";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch } from "react-redux";

import axios from "axios";
import qs from "qs";
import moment from "moment";
import toast from "react-hot-toast";

export default function ProductList({ selectedAppointment, initial, male }) {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const { reportData, chat, token } = useSelector((state) => state.counter);
  const [selectedTab, setSelectedTab] = useState("AnteriorView");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [segment, setSegment] = useState("");
  const [appointmentId, setAppointmentId] = useState("");

  const handleClose = () => setShow(false);

  useEffect(() => {
    setSelectedTab(reportData.tab);
  }, [reportData]);

  useEffect(() => {
    if (chat && chat.openModal) {
      setShow(true);
      getMessages(chat.selectedAppointment, chat.segment);
      setSegment(chat.segment);
      setAppointmentId(chat.selectedAppointment);
    }
  }, [chat]);

  const getMessages = async (appointment, segment) => {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/recommendations/list?segment=" + segment + "&appointment=" + appointment, options);
    if (Response.data.success) {
      setData(Response.data.result);
    }
  };

  useEffect(() => {
    // Scroll to the bottom when component mounts or when data changes
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [data]);

  const sendMessages = async () => {
    if (message.trim() == "") {
      return toast.error("Enter message");
    }
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/recommendations/add/",
      qs.stringify({
        appointment: appointmentId,
        segment: segment,
        by: "practitioner",
        message: message,
      }),
      options
    );
    if (Response.data.status == "success") {
      setMessage("");
      getMessages(appointmentId, segment);
    } else {
      toast.error(Response.data.message);
    }
  };

  useEffect(() => {
    if (show) {
      dispatch(
        setChatData({
          openModal: false,
          selectedAppointment: "",
          segment: "",
        })
      );
    }
  }, [show]);

  return (
    <>
      {initial && male && (
        <Tabs activeKey={selectedTab} defaultActiveKey="AnteriorView" className="mb-3" onSelect={(k) => setSelectedTab(k)}>
          <Tab eventKey="AnteriorView" title="Anterior View of Male">
            <MenAtsFront selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="PosteriorView" title="Posterior View of Male">
            <MenAtsBack selectedAppointment={selectedAppointment} />
          </Tab>
        </Tabs>
      )}

      {initial && !male && (
        <Tabs activeKey={selectedTab} defaultActiveKey="AnteriorView" className="mb-3" onSelect={(k) => setSelectedTab(k)}>
          <Tab eventKey="AnteriorView" title="Anterior View of Female">
            <WomenAtsFront selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="PosteriorView" title="Posterior View of Female">
            <WomenAtsBack selectedAppointment={selectedAppointment} />
          </Tab>
        </Tabs>
      )}

      {!initial && male && (
        <Tabs activeKey={selectedTab} defaultActiveKey="AnteriorView" className="mb-3" onSelect={(k) => setSelectedTab(k)}>
          <Tab eventKey="AnteriorView" title="Anterior View of Male">
            <MenAtsFrontMuscle selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="PosteriorView" title="Posterior View of Male">
            <MenAtsBackMuscle selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="InternalCavity" title="Internal Cavity">
            <InternalCavity selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="Diaphragm" title="Right Half of Diaphragm">
            <Diaphragm selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="LeftHand" title="Left Hand">
            <LeftHand selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="RightHand" title="Right Hand">
            <RightHand selectedAppointment={selectedAppointment} />
          </Tab>

          <Tab eventKey="LeftFoot" title="Left Foot">
            <LeftFoot selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="RightFoot" title="Right Foot">
            <RightFoot selectedAppointment={selectedAppointment} />
          </Tab>
        </Tabs>
      )}

      {!initial && !male && (
        <Tabs activeKey={selectedTab} defaultActiveKey="AnteriorView" className="mb-3" onSelect={(k) => setSelectedTab(k)}>
          <Tab eventKey="AnteriorView" title="Anterior View of Female">
            <WomenAtsFrontMuscle selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="PosteriorView" title="Posterior View of Female">
            <WomenAtsBackMuscle selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="InternalCavity" title="Internal Cavity">
            <InternalCavity selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="Diaphragm" title="Right Half of Diaphragm">
            <Diaphragm selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="LeftHand" title="Left Hand">
            <LeftHand selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="RightHand" title="Right Hand">
            <RightHand selectedAppointment={selectedAppointment} />
          </Tab>

          <Tab eventKey="LeftFoot" title="Left Foot">
            <LeftFoot selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey="RightFoot" title="Right Foot">
            <RightFoot selectedAppointment={selectedAppointment} />
          </Tab>
        </Tabs>
      )}
      <div>
        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Recommendations</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <>
              <div className="card border-0">
                <div className="card-body" style={{ maxHeight: 330, overflow: "auto" }} ref={scrollRef}>
                  {data?.length > 0 &&
                    data.map((item, key) => (
                      <>
                        {item.by == "practitioner" ? (
                          <div className="d-flex flex-row justify-content-start mb-4" key={key}>
                            <div className="p-3 ms-3" style={{ borderRadius: 15, backgroundColor: "rgba(57, 192, 237,.2)" }}>
                              <strong>You</strong>
                              <p className="small mb-0">{item.message}</p>
                              <small>
                                <i className="text-muted">sent at {moment(item.createdAt).fromNow()} </i>
                              </small>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex flex-row justify-content-end mb-4" key={key}>
                            <div className="p-3 me-3 border" style={{ borderRadius: 15, backgroundColor: "#fbfbfb" }}>
                              <strong>User</strong>
                              <p className="small mb-0">{item.message}</p>
                              <small>
                                <i className="text-muted">sent at {moment(item.createdAt).fromNow()} </i>
                              </small>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
                <div className="card-footer border-0 bg-white">
                  <div className="form-outline">
                    <textarea className="form-control" id="textAreaExample" placeholder=" Type your message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                  </div>
                  <button type="button" className="btn btn-primary w-100 mt-3" onClick={() => sendMessages()}>
                    Send
                  </button>
                </div>
              </div>
            </>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}
