import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ToggleButton from "react-toggle-button";
export default function ProductList() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");

  const [emergency_contact_name, setEmergencyName] = useState("");
  const [emergency_contact_phone, setEmergencyPhone] = useState("");
  const [emergency_contact_relation, setEmergencyRelation] = useState("");
  const [primary_care_physician_name, setPhysicianName] = useState("");
  const [primary_care_physician_phone, setPhysicianPhone] = useState("");
  const [primary_care_physician_email, setEmergencyEmail] = useState("");
  const [primary_care_physician_address, setEmergencyAddress] = useState("");

  const [pastInjuries, setPastInjuries] = useState(false);
  const [difficultLaying, setDifficultLaying] = useState(false);
  const [allergy, setAllergy] = useState(false);
  const [contactLenses, setContactLenses] = useState(false);
  const [dentures, setDentures] = useState(false);
  const [hearingAids, setHearingAids] = useState(false);
  const [weightTraining, setWeightTraining] = useState(false);
  const [stretchTraining, setStretchTraining] = useState(false);
  const [activities, setActivities] = useState({
    how_i_see_this: false,
    how_i_feel: false,
    what_i_belive: false,
    my_relationships: false,
    event_attending: false,
    resources: false,
    related_information: false,
    time: false,
    work: false,
    other: false,
  });

  const [goal, setGoal] = useState("");
  const [otherInfo, setOtherInfo] = useState("");

  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPhone, setFilterPhone] = useState("");

  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  async function handlepatient() {
    if (!name && name == "") {
      toast.error("Enter patient name");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/patient/add",
        {
          name: name,
          email: email,
          phone: phone,
          gender: gender,
          dob: dob == "" ? null : dob,
          dobUnix: dob == "" ? 0 : moment(dob).format("X"),
          practitioner: userData._id,

          emergency_contact_name: emergency_contact_name,
          emergency_contact_phone: emergency_contact_phone,
          emergency_contact_relation: emergency_contact_relation,
          primary_care_physician_name: primary_care_physician_name,
          primary_care_physician_phone: primary_care_physician_phone,
          primary_care_physician_email: primary_care_physician_email,
          primary_care_physician_address: primary_care_physician_address,
          pastInjuries: pastInjuries,
          difficultLaying: difficultLaying,
          allergy: allergy,
          contactLenses: contactLenses,
          dentures: dentures,
          hearingAids: hearingAids,
          weightTraining: weightTraining,
          stretchTraining: stretchTraining,
          activities: activities,
          goal: goal,
          otherInfo: otherInfo,
        },
        options
      );
      if (Response.data.status == "success") {
        setName("");
        setEmail("");
        setPhone("");
        setGender("");
        setDob("");

        setEmergencyName("");
        setEmergencyPhone("");
        setEmergencyRelation("");
        setPhysicianName("");
        setPhysicianPhone("");
        setEmergencyEmail("");
        setEmergencyAddress("");

        setPastInjuries("");
        setDifficultLaying("");
        setAllergy("");
        setContactLenses("");
        setDentures("");
        setHearingAids("");
        setWeightTraining("");
        setStretchTraining("");
        setActivities("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    } else {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/patient/update/" + id,
        {
          name: name,
          email: email,
          phone: phone,
          gender: gender,
          dob: dob == "" ? null : dob,
          dobUnix: dob == "" ? 0 : moment(dob).format("X"),
          practitioner: userData._id,

          emergency_contact_name: emergency_contact_name,
          emergency_contact_phone: emergency_contact_phone,
          emergency_contact_relation: emergency_contact_relation,
          primary_care_physician_name: primary_care_physician_name,
          primary_care_physician_phone: primary_care_physician_phone,
          primary_care_physician_email: primary_care_physician_email,
          primary_care_physician_address: primary_care_physician_address,
          pastInjuries: pastInjuries,
          difficultLaying: difficultLaying,
          allergy: allergy,
          contactLenses: contactLenses,
          dentures: dentures,
          hearingAids: hearingAids,
          weightTraining: weightTraining,
          stretchTraining: stretchTraining,
          activities: activities,
          goal: goal,
          otherInfo: otherInfo,
        },
        options
      );
      if (Response.data.status == "success") {
        setName("");
        setEmail("");
        setPhone("");
        setGender("");
        setDob("");

        setEmergencyName("");
        setEmergencyPhone("");
        setEmergencyRelation("");
        setPhysicianName("");
        setPhysicianPhone("");
        setEmergencyEmail("");
        setEmergencyAddress("");

        setPastInjuries("");
        setDifficultLaying("");
        setAllergy("");
        setContactLenses("");
        setDentures("");
        setHearingAids("");
        setWeightTraining("");
        setStretchTraining("");
        setActivities("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      name: filterName,
      email: filterEmail,
      phone: filterPhone,
      gender: gender,
      dob: dob,
      practitioner: userData._id,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/patient/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/patient/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  // useEffect(() => {
  //   console.log(activities);
  // }, [activities]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Manage patient"}>
              <li className="mc-breadcrumb-item">
                <Anchor
                  icon={"add"}
                  text={"Add patient"}
                  href={"#"}
                  className="mc-btn primary"
                  onClick={() => {
                    setShow(true);
                    setName("");
                    setEmail("");
                    setPhone("");
                    setGender("");
                    setDob("");

                    setEmergencyName("");
                    setEmergencyPhone("");
                    setEmergencyRelation("");
                    setPhysicianName("");
                    setPhysicianPhone("");
                    setEmergencyEmail("");
                    setEmergencyAddress("");
                    setPastInjuries("");
                    setDifficultLaying("");
                    setAllergy("");
                    setContactLenses("");
                    setDentures("");
                    setHearingAids("");
                    setWeightTraining("");
                    setStretchTraining("");
                    setActivities("");
                  }}
                />
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={3}>
                <div className="form-group">
                  <input placeholder="Filter by patient name" type="text" className="form-control" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                </div>
              </Col>

              <Col xl={3}>
                <div className="form-group">
                  <input placeholder="Filter by patient email" type="text" className="form-control" value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} />
                </div>
              </Col>

              <Col xl={3}>
                <div className="form-group">
                  <input placeholder="Filter by patient phone" type="text" className="form-control" value={filterPhone} onChange={(e) => setFilterPhone(e.target.value)} />
                </div>
              </Col>

              <Col xl={3}>
                <Anchor
                  icon={"search"}
                  text={"Search"}
                  href={"#"}
                  className="mc-btn primary w-100"
                  onClick={() => {
                    setActivePage(1);
                    fetchData(1);
                  }}
                />
              </Col>

              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Phone</Th>
                        <Th>Balance</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>{item.name}</Td>
                            <Td>{item.email} </Td>
                            <Td>{item.phone}</Td>
                            <Td>{item.balance} Minute</Td>

                            <Td>
                              <Box className="mc-table-action">
                                <Anchor href={"/patient-details?id=" + item._id} title="View" className="view">
                                  View
                                </Anchor>

                                <Button
                                  href="#"
                                  title="Edit"
                                  className="edit"
                                  onClick={() => {
                                    setMode("edit");
                                    setName(item.name);
                                    setEmail(item.email);
                                    setPhone(item.phone);
                                    setGender(item.gender);
                                    setDob(item.dob);
                                    setId(item._id);
                                    setShow(true);

                                    setEmergencyName(item.emergency_contact_name);
                                    setEmergencyPhone(item.emergency_contact_phone);
                                    setEmergencyRelation(item.emergency_contact_relation);
                                    setPhysicianName(item.primary_care_physician_name);
                                    setPhysicianPhone(item.primary_care_physician_phone);
                                    setEmergencyEmail(item.primary_care_physician_email);
                                    setEmergencyAddress(item.primary_care_physician_address);

                                    setPastInjuries(item.pastInjuries);
                                    setDifficultLaying(item.difficultLaying);
                                    setAllergy(item.allergy);
                                    setContactLenses(item.contactLenses);
                                    setDentures(item.dentures);
                                    setHearingAids(item.hearingAids);
                                    setWeightTraining(item.weightTraining);
                                    setStretchTraining(item.stretchTraining);
                                    setActivities(item.activities);
                                  }}
                                >
                                  Edit
                                </Button>

                                <Button
                                  title="Delete"
                                  className="delete"
                                  onClick={() => {
                                    setId(item._id);
                                    setShowSwal(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"} style={{ width: "560px" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} patient</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs defaultActiveKey="Personal" className="mb-3">
            <Tab eventKey="Personal" title="Personal">
              <Row>
                <Col xl={12}>
                  <LabelField label="name" type="text" fieldSize="w-100 h-md" value={name} onChange={(e) => setName(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <LabelField label="email (Optional)" type="email" fieldSize="w-100 h-md" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <LabelField label="phone (Optional)" type="text" fieldSize="w-100 h-md" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <div class="mc-label-field-group label-col">
                    <label class="mc-label-field-title">Gender (Optional)</label>
                    <select class="mc-label-field-input w-100 h-md" onChange={(e) => setGender(e.target.value)} value={gender}>
                      <option value={""}> Select </option>
                      <option value={"M"}> Male </option>
                      <option value={"F"}> Female </option>
                      <option value={"O"}> Other </option>
                    </select>
                  </div>
                </Col>

                <Col xl={12}>
                  <LabelField label="date of birth (Optional)" type="date" fieldSize="w-100 h-md" value={dob} onChange={(e) => setDob(e.target.value)} />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="Emergency" title="Emergency">
              <Row>
                <Col xl={12}>
                  <LabelField label="Emergency contact name" type="text" fieldSize="w-100 h-md" value={emergency_contact_name} onChange={(e) => setEmergencyName(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <LabelField label="Emergency contact phone" type="number" fieldSize="w-100 h-md" value={emergency_contact_phone} onChange={(e) => setEmergencyPhone(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <LabelField label="Emergency contact relationship with you" type="text" fieldSize="w-100 h-md" value={emergency_contact_relation} onChange={(e) => setEmergencyRelation(e.target.value)} />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="Primary" title="Primary Care">
              <Row>
                <Col xl={12}>
                  <LabelField label="Physician's Name" type="text" fieldSize="w-100 h-md" value={primary_care_physician_name} onChange={(e) => setPhysicianName(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <LabelField label="Physician's Phone" type="number" fieldSize="w-100 h-md" value={primary_care_physician_phone} onChange={(e) => setPhysicianPhone(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <LabelField label="Physician's Email" type="email" fieldSize="w-100 h-md" value={primary_care_physician_email} onChange={(e) => setEmergencyEmail(e.target.value)} />
                </Col>

                <Col xl={12}>
                  <div class="mc-label-field-group label-col">
                    <label class="mc-label-field-title"> Physician's Adddress</label>
                    <textarea placeholder="Type here..." class="mc-label-field-input w-100 h-md" value={primary_care_physician_address} onChange={(e) => setEmergencyAddress(e.target.value)}></textarea>
                  </div>
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="Record" title="Health Record">
              <Row className="mt-3">
                <Col xl={9}>Do you have past or current injuries?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={pastInjuries}
                    onToggle={(value) => {
                      setPastInjuries(value ? false : true);
                    }}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={9}>Do you have difficult laying on your front, back, or side?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={difficultLaying}
                    onToggle={(value) => {
                      setDifficultLaying(value ? false : true);
                    }}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={9}>Do you have an allergy to oils, lotions, or ointments?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={allergy}
                    onToggle={(value) => {
                      setAllergy(value ? false : true);
                    }}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={9}>Do you wear contact lenses?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={contactLenses}
                    onToggle={(value) => {
                      setContactLenses(value ? false : true);
                    }}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={9}>Do you wear dentures?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={dentures}
                    onToggle={(value) => {
                      setDentures(value ? false : true);
                    }}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={9}>Do you wear hearing aids?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={hearingAids}
                    onToggle={(value) => {
                      setHearingAids(value ? false : true);
                    }}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={9}>Do you currently participate in resistance (weight) training routine?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={weightTraining}
                    onToggle={(value) => {
                      setWeightTraining(value ? false : true);
                    }}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={9}>Do you currently participate in a stretch training routine?</Col>
                <Col xl={3}>
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={stretchTraining}
                    onToggle={(value) => {
                      setStretchTraining(value ? false : true);
                    }}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={12}>I am participating in the following activities to help me feel better?</Col>
                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.how_i_see_this}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.how_i_see_this = activities.how_i_see_this ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">How I see things</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.how_i_feel}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.how_i_feel = activities.how_i_feel ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">How I feel</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.what_i_belive}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.what_i_belive = activities.what_i_belive ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">What I Believe</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.my_relationships}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.my_relationships = activities.my_relationships ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">My Relationships</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.event_attending}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.event_attending = activities.event_attending ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">Event attending</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.resources}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.resources = activities.resources ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">Resources</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.related_information}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.related_information = activities.related_information ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">Related Information</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.time}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.time = activities.time ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">Time</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.work}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.work = activities.work ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">Work</label>
                  </div>
                </Col>

                <Col xl={4}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={activities.other}
                      onChange={() => {
                        var newActivities = { ...activities };
                        newActivities.other = activities.other ? false : true;
                        setActivities(newActivities);
                      }}
                    />
                    <label class="form-check-label">Other</label>
                  </div>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xl={12}>What goals do you want to achieve through these sessions?</Col>
                <Col xl={12}>
                  <textarea class="mc-label-field-input w-100 h-md" value={goal} onChange={(e) => setGoal(e.target.value)}></textarea>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={12}>Is there any other significant information about your physical or mental health that your D- Practitioner should know?</Col>
                <Col xl={12}>
                  <textarea class="mc-label-field-input w-100 h-md" value={otherInfo} onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                </Col>
              </Row>
            </Tab>
          </Tabs>

          <Button className="mc-btn primary w-100 mt-3" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handlepatient()}>
            {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
          </Button>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This data will be permanently deleted.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
