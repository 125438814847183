import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, Button } from "./../../components/elements";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import moment from "moment";

import MenAtsFrontSingle from "./menAtsFrontSingle";
import MenAtsBackSingle from "./menAtsBackSingle";
import WomenAtsFrontSingle from "./womenAtsFrontSingle";
import WomenAtsBackSingle from "./womenAtsBackSingle";
import MenAtsFrontMuscleSingle from "./menAtsFrontMuscleSingle";
import MenAtsBackMuscleSingle from "./menAtsBackMuscleSingle";
import WomenAtsFrontMuscleSingle from "./womenAtsFrontMuscleSingle";
import WomenAtsBackMuscleSingle from "./womenAtsBackMuscleSingle";
import InternalCavitySingle from "./internalCavitySingle";
import DiaphragmSingle from "./DiaphragmSingle";
import LeftHandSingle from "./LeftHandSingle";
import RightHandSingle from "./RightHandSingle";
import LeftFootSingle from "./LeftFootSingle";
import RightFootSingle from "./RightFootSingle";

export default function Report() {
  const { userData, token, refreshToken, saveIndicator } = useSelector((state) => state.counter);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData(1);
  }, [saveIndicator]);

  async function fetchData(pageNumber) {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      patient: id,
      practitioner: userData._id,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/user-appointment?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }
  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  return (
    <>
      <Row>
        <br />
        <div class="alert alert-info text-center" role="alert">
          <b>&#9432;</b> Click on body to open the details
        </div>
        {data?.length > 0 &&
          data.map((item, key) => (
            <Col xl={12}>
              <h5> {moment(item.appointmentDate).format("MMMM Do YYYY")}</h5>
              <hr />

              <div className="row">
                {item.anteriorViewMale && item.anteriorViewMale.length > 0 && (
                  <div className="col-md-2">
                    <MenAtsFrontSingle key={key} selectedAppointment={item.anteriorViewMale} id={item._id} />
                  </div>
                )}

                {item.posteriorViewMale && item.posteriorViewMale.length > 0 && (
                  <div className="col-md-2">
                    <MenAtsBackSingle key={key} selectedAppointment={item.posteriorViewMale} id={item._id} />
                  </div>
                )}

                {item.anteriorViewFeMale && item.anteriorViewFeMale.length > 0 && (
                  <div className="col-md-2">
                    <WomenAtsFrontSingle key={key} selectedAppointment={item.anteriorViewFeMale} id={item._id} />
                  </div>
                )}

                {item.posteriorViewFeMale && item.posteriorViewFeMale.length > 0 && (
                  <div className="col-md-2">
                    <WomenAtsBackSingle key={key} selectedAppointment={item.posteriorViewFeMale} id={item._id} />
                  </div>
                )}

                {item.anteriorViewMaleMuscle && item.anteriorViewMaleMuscle.length > 0 && (
                  <div className="col-md-2">
                    <MenAtsFrontMuscleSingle key={key} selectedAppointment={item.anteriorViewMaleMuscle} id={item._id} />
                  </div>
                )}

                {item.posteriorViewMaleMuscle && item.posteriorViewMaleMuscle.length > 0 && (
                  <div className="col-md-2">
                    <MenAtsBackMuscleSingle key={key} selectedAppointment={item.posteriorViewMaleMuscle} id={item._id} />
                  </div>
                )}

                {item.posteriorViewFeMaleMuscle && item.posteriorViewFeMaleMuscle.length > 0 && (
                  <div className="col-md-2">
                    <WomenAtsFrontMuscleSingle key={key} selectedAppointment={item.posteriorViewFeMaleMuscle} id={item._id} />
                  </div>
                )}

                {item.anteriorViewFeMaleMuscle && item.anteriorViewFeMaleMuscle.length > 0 && (
                  <div className="col-md-2">
                    <WomenAtsBackMuscleSingle key={key} selectedAppointment={item.anteriorViewFeMaleMuscle} id={item._id} />
                  </div>
                )}

                {item.rightFoot && item.rightFoot.length > 0 && (
                  <div className="col-md-2">
                    <LeftFootSingle key={key} selectedAppointment={item.rightFoot} id={item._id} />
                  </div>
                )}

                {item.leftFoot && item.leftFoot.length > 0 && (
                  <div className="col-md-2">
                    <RightFootSingle key={key} selectedAppointment={item.leftFoot} id={item._id} />
                  </div>
                )}

                {item.leftHand && item.leftHand.length > 0 && (
                  <div className="col-md-3">
                    <LeftHandSingle key={key} selectedAppointment={item.rightHand} id={item._id} />
                  </div>
                )}

                {item.rightHand && item.rightHand.length > 0 && (
                  <div className="col-md-3">
                    <RightHandSingle key={key} selectedAppointment={item.leftHand} id={item._id} />
                  </div>
                )}

                {item.internalCavity && item.internalCavity.length > 0 && (
                  <div className="col-md-3">
                    <InternalCavitySingle key={key} selectedAppointment={item.internalCavity} id={item._id} />
                  </div>
                )}

                {item.diaphragm && item.diaphragm.length > 0 && (
                  <div className="col-md-3">
                    <DiaphragmSingle key={key} selectedAppointment={item.diaphragm} id={item._id} />
                  </div>
                )}
              </div>
            </Col>
          ))}
      </Row>

      <Box className="mc-paginate">
        <Text className="mc-paginate-title">
          Total <b>{totalRecod}</b> Results Found
        </Text>
        <nav aria-label="Page navigation example">
          {totalRecod > perPage ? (
            <Pagination
              prevPageText="prev"
              nextPageText="next"
              firstPageText="first"
              lastPageText="last"
              activePage={activePage}
              itemsCountPerPage={perPage}
              totalItemsCount={totalRecod}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination justify-content-center"
            />
          ) : (
            ""
          )}
        </nav>
      </Box>
    </>
  );
}
