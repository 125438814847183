import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

function BreaksModal({ show, onHide, dayOfWeek, breaks, onSave }) {
  const [breakToAdd, setBreakToAdd] = useState({ start: "", end: "" });

  const handleSaveBreak = () => {
    const updatedBreaks = [...breaks, { ...breakToAdd }];
    try {
      onSave(dayOfWeek, updatedBreaks);
      setBreakToAdd({ start: "", end: "" });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Manage Breaks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {breaks.map((breakTime, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {" "}
                    <input
                      type="time"
                      value={breakTime.start}
                      onChange={(e) => {
                        const updatedBreaks = [...breaks];
                        updatedBreaks[index].start = e.target.value;
                        onSave(dayOfWeek, updatedBreaks);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      value={breakTime.end}
                      onChange={(e) => {
                        const updatedBreaks = [...breaks];
                        updatedBreaks[index].end = e.target.value;
                        onSave(dayOfWeek, updatedBreaks);
                      }}
                    />
                  </td>
                  <td>
                    {" "}
                    <Button
                      variant="danger"
                      onClick={() => {
                        const updatedBreaks = [...breaks];
                        updatedBreaks.splice(index, 1);
                        onSave(dayOfWeek, updatedBreaks);
                      }}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {breaks.length == 0 && <div className="text-center">No Breacks</div>}
          <br />

          <div>
            <h5 className="text-center mb-3">Add New Break</h5>
            <div className="row">
              <div className="col-md-4">
                <label>Start Time</label>
                <Form.Control type="time" value={breakToAdd.start} onChange={(e) => setBreakToAdd({ ...breakToAdd, start: e.target.value })} />
              </div>
              <div className="col-md-4">
                <label>End Time</label>
                <Form.Control type="time" value={breakToAdd.end} onChange={(e) => setBreakToAdd({ ...breakToAdd, end: e.target.value })} />
              </div>
              <div className="col-md-4">
                <br />
                <Button variant="primary" onClick={handleSaveBreak} className="w-100">
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default BreaksModal;
