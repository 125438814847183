import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { Button, Icon } from "./../../components/elements";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Box, Text } from "./../../components/elements";
import Pagination from "react-js-pagination";
import { CardLayout } from "./../../components/cards";

export default function CRM() {
  const { token, userData } = useSelector((state) => state.counter);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterAptId, setFilterAptId] = useState("");

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      startdate: filterStartDate,
      enddate: filterEndDate,
      practitioner: userData._id,
      id: filterAptId,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/all-appointment?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Appointments"}></Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={3}>
                <div className="form-group">
                  <label>Appointment id</label>
                  <input type="text" className="form-control" value={filterAptId} onChange={(e) => setFilterAptId(e.target.value)} />
                </div>
              </Col>

              <Col xl={3}>
                <div className="form-group">
                  <label>Start date</label>
                  <input type="date" className="form-control" value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                </div>
              </Col>

              <Col xl={3}>
                <div className="form-group">
                  <label>End date</label>
                  <input type="date" className="form-control" value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                </div>
              </Col>

              <Col xl={3}>
                <br />
                <Button
                  icon={"search"}
                  text={"Search"}
                  href={"#"}
                  className="mc-btn primary w-100"
                  onClick={() => {
                    setActivePage(1);
                    fetchData(1);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Box className="mc-table-responsive">
              <Table className="mc-table">
                <Thead className="mc-table-head primary">
                  <Tr>
                    <Th># ID</Th>
                    <Th>Date/Time</Th>

                    <Th>Patient</Th>
                  </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                  {data?.length > 0 &&
                    data.map((item, key) => (
                      <Tr key={key}>
                        <Td> {item.uniqueId}</Td>
                        <Td>
                          {moment(item.appointmentDate).format("DD/MM/YYYY")} <br /> {moment.unix(item.startTime).format("hh:mm A")} - {moment.unix(item.endTime).format("hh:mm A")}
                        </Td>

                        <Td>
                          <a class="mc-sidebar-menu-btn" href={"/patient-details?id=" + item.patient._id}>
                            <i class="material-icons">link</i>
                            <span>
                              {item.patient.name} {item.patient.gender != "" && "(" + item.patient.gender + ")"} {item.patient.email != "" && "- (" + item.patient.email + ")"}
                            </span>
                          </a>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            <Box className="mc-paginate">
              <Text className="mc-paginate-title">
                Total <b>{totalRecod}</b> Results Found
              </Text>
              <nav aria-label="Page navigation example">
                {totalRecod > perPage ? (
                  <Pagination
                    prevPageText="prev"
                    nextPageText="next"
                    firstPageText="first"
                    lastPageText="last"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalRecod}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                ) : (
                  ""
                )}
              </nav>
            </Box>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
