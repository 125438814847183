import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";

export default function CRM() {
  const { token, userData } = useSelector((state) => state.counter);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/dasborad/admin?practitioner=" + userData._id, options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={"Dashboard"}></Breadcrumb>
        </Col>

        <div className="row">
          <div className="col-xl-3">
            <div className="mc-analytics-card bg-primary">
              <div className="mc-analytics-card-group">
                <div className="mc-analytics-card-content">
                  <h3>{data.patients}</h3>
                  <p>Total Patients</p>
                </div>
              </div>
              <div className="recharts-responsive-container" width="190.75" height="40">
                <div className="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div className="recharts-default-tooltip">
                      <p className="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="mc-analytics-card bg-primary">
              <div className="mc-analytics-card-group">
                <div className="mc-analytics-card-content">
                  <h3>{data.todayBookings}</h3>
                  <p>Today Bookings</p>
                </div>
              </div>
              <div className="recharts-responsive-container" width="190.75" height="40">
                <div className="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div className="recharts-default-tooltip">
                      <p className="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="mc-analytics-card bg-primary">
              <div className="mc-analytics-card-group">
                <div className="mc-analytics-card-content">
                  <h3>{data.currentWeekBookings}</h3>
                  <p>7 Day Bookings</p>
                </div>
              </div>
              <div className="recharts-responsive-container" width="190.75" height="40">
                <div className="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div className="recharts-default-tooltip">
                      <p className="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="mc-analytics-card bg-primary">
              <div className="mc-analytics-card-group">
                <div className="mc-analytics-card-content">
                  <h3>{data.planData && data.planData.subscription && data.planData.subscription.title} Plan</h3>

                  {data.planData && data.planData.planActive == 0 && (
                    <>{data.planData.planStartTimestamp == 0 && data.planData.planActive == 0 && moment().format("X") > data.planData.trialExpTimestamp ? <span className="p-1 rounded bg-danger text-white">Trial Expired</span> : <span className="p-1 rounded bg-success text-white">In Trial</span>}</>
                  )}
                  {data.planData && data.planData.planActive == 1 && (
                    <>{data.planData && data.planData.planActive == 1 && moment().format("X") > data.planData.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>
                  )}
                </div>
              </div>
              <div className="recharts-responsive-container" width="190.75" height="40">
                <div className="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div className="recharts-default-tooltip">
                      <p className="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 className="mt-5 mb-2 text-center">Upcoming 7 day appointment</h4>
        <div className="bg-white p-3 m-2 rounded">
          {data.bookings?.length > 0 &&
            data.bookings.map((item, key) => (
              <>
                <p className="p-2 mb-3 text-muted">
                  <b>Appointments of {item._id}</b>
                </p>
                <hr />
                <table className="table table-sm">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"># ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Patient</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.appointments?.length > 0 &&
                      item.appointments.map((item2, key) => (
                        <tr>
                          <td>{item2.uniqueId}</td>
                          <td>{item2.appointmentDate}</td>
                          <td>
                            {moment(item2.startTime).format("HH:mmA")} - {moment(item2.endTime).format("HH:mmA")}{" "}
                          </td>
                          <td>
                            <a class="mc-sidebar-menu-btn" href={"/patient-details?id=" + item2.patient._id}>
                              <i class="material-icons">link</i>
                              <span>
                                {item2.patient.name} {item2.patient.gender != "" && "(" + item2.patient.gender + ")"} {item2.patient.email != "" && "- (" + item2.patient.email + ")"}
                              </span>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ))}
        </div>
      </Row>
    </PageLayout>
  );
}
