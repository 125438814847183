export const incrementCounter = () => {
  return {
    type: "INCREMENT_COUNTER",
  };
};

export const decrementCounter = () => {
  return {
    type: "DECREMENT_COUNTER",
  };
};

export const setUserData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_USER_DATA",
    payload: payload,
  });
};

export const setLoginStatus = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_LOGIN_STATUS",
    payload: payload,
  });
};

export const setToken = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_TOKEN",
    payload: payload,
  });
};

export const setRefreshToken = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_REFRESH_TOKEN",
    payload: payload,
  });
};

export const setSaveIndicator = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_SAVE_INDICATOR",
    payload: payload,
  });
};

export const setReportData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_REPORT_DATA",
    payload: payload,
  });
};

export const setChatData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_CHAT_DATA",
    payload: payload,
  });
};
