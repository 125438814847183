import React from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText } from "..";
import { Anchor } from "../elements";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setUserData, setToken, setRefreshToken, setLoginStatus } from "../../actions";
export default function ProfileDropdown({ name, username, image, dropdown }) {
  const navigate = useNavigate();
  async function handleLogout() {
    setUserData([]);
    setLoginStatus(false);
    setToken("");
    setRefreshToken("");
    toast.success("Logged out");
    navigate("/");
  }

  return (
    <Dropdown className="mc-header-user">
      <Dropdown.Toggle className="mc-dropdown-toggle">
        <DuelText title={name} descrip={username} size="xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="mc-dropdown-paper">
        {dropdown.map((item, index) => (
          <Anchor key={index} href={item.path} icon={item.icon} text={item.text} className="mc-dropdown-menu" onClick={() => (item.text == "logout" ? handleLogout() : null)} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
