import React from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Button } from "../../components/elements";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import BreakModal from "./BreaksModal";

export default function Alerts() {
  const days = ["Sun", "Mon", "Tues", "Wed", "Thrus", "Fri", "Sat"];
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showBreaksModal, setShowBreaksModal] = useState(false);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(0);
  const [businessHours, setBusinessHours] = useState([]);

  useEffect(() => {
    // Fetch and load business hours from the server
    fetchBusinessHours();
  }, []);

  const fetchBusinessHours = () => {
    const options = {
      headers: { authorization: token },
    };
    axios
      .get(process.env.REACT_APP_API_URL + "app/business-hours?user=" + userData._id, options)
      .then((response) => {
        setBusinessHours(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching business hours:", error);
      });
  };

  const handleSave = () => {
    const options = {
      headers: { authorization: token },
    };
    axios
      .post(process.env.REACT_APP_API_URL + "app/business-hours/update", { hours: businessHours }, options)
      .then((response) => {
        toast.success("Business hours saved");
      })
      .catch((error) => {
        toast.error("Error saving business hours:", error.message);
      });
  };

  const handleSaveBreaks = (dayOfWeek, breaks) => {
    // Log the breaks for debugging purposes

    try {
      // Update the business hours data with the new breaks
      const updatedBusinessHours = businessHours.map((hour) => (hour.dayOfWeek === dayOfWeek ? { ...hour, breakTimes: breaks } : hour));
      // Set the updated business hours in the state
      setBusinessHours(updatedBusinessHours);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Col xl={12} className="ps-xl-5">
          <Row>
            <Col xl={12}>
              <div className="">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Day of the week</th>
                      <th>Is Open</th>
                      <th>Open Time</th>
                      <th>Close Time</th>
                      <th>Break Times</th>
                    </tr>
                  </thead>
                  <tbody>
                    {businessHours.map((hours, index) => (
                      <tr key={index}>
                        <td>{days[index]}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={hours.isOpen}
                            onChange={() => {
                              const updatedHours = [...businessHours];
                              updatedHours[index].isOpen = !updatedHours[index].isOpen;
                              setBusinessHours(updatedHours);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="time"
                            value={hours.openTime}
                            onChange={(e) => {
                              const updatedHours = [...businessHours];
                              updatedHours[index].openTime = e.target.value;
                              setBusinessHours(updatedHours);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="time"
                            value={hours.closeTime}
                            onChange={(e) => {
                              const updatedHours = [...businessHours];
                              updatedHours[index].closeTime = e.target.value;
                              setBusinessHours(updatedHours);
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              setShowBreaksModal(true);
                              setSelectedDayOfWeek(index);
                            }}
                          >
                            Manage Breaks
                          </Button>
                          <BreakModal show={showBreaksModal} onHide={() => setShowBreaksModal(false)} dayOfWeek={selectedDayOfWeek} breaks={businessHours.find((hour) => hour.dayOfWeek === selectedDayOfWeek)?.breakTimes || []} onSave={handleSaveBreaks} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="text-center">
                  <button className="btn btn-primary" onClick={handleSave} type="button">
                    Save Business Hours
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
