import React from "react";
import { Box, Item, Anchor } from "../../components/elements";
import { Breadcrumb } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "suneditor/dist/css/suneditor.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import WebsiteSetting from "../blocks/WebsiteSetting";
import PaymentSetting from "../blocks/PaymentSetting";
import Packages from "../blocks/Packages";
import BusinessHours from "../blocks/BusinessHours";
import Holidays from "../blocks/Holidays";
import Rooms from "../blocks/Rooms";

const Settings = () => {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [currenttab, setcurrenttab] = useState(0);

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <div className="mt-3 mb-2">
            <Tabs activeKey={currenttab} id="uncontrolled-tab-example" className="mb-3 businessSettings" justify onSelect={(key) => setcurrenttab(key)}>
              <Tab eventKey={0} title="Website ">
                <WebsiteSetting />
              </Tab>
              <Tab eventKey={1} title="Business Hours">
                <BusinessHours />
              </Tab>
              <Tab eventKey={2} title="Rooms">
                <Rooms />
              </Tab>
              <Tab eventKey={3} title="Holiday">
                <Holidays />
              </Tab>
              <Tab eventKey={4} title="Payment Method">
                <PaymentSetting />
              </Tab>{" "}
              <Tab eventKey={5} title="Packages">
                <Packages />
              </Tab>
            </Tabs>
          </div>
        </Box>
      </CardLayout>
    </PageLayout>
  );
};

export default Settings;
