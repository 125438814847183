import React, { useEffect, useState } from "react";
import { setReportData } from "../../actions";
import { useDispatch } from "react-redux";
export default function ProductList({ selectedAppointment, id }) {
  const dispatch = useDispatch();
  useEffect(() => {
    const svgObject = document.querySelector('object[data="' + process.env.REACT_APP_SVG_URL + "images/basic_men_back.svg?id=" + id + '"]');
    if (svgObject) {
      svgObject.addEventListener("load", () => {
        const svgDocument = svgObject.contentDocument;
        const pathElements = svgDocument.querySelectorAll("path");

        pathElements.forEach((path) => {
          path.addEventListener("click", () => {
            //
            dispatch(
              setReportData({
                openModal: true,
                selectedAppointment: id,
                initial: true,
                male: true,
                tab: "PosteriorView",
              })
            );
          });
        });

        setTimeout(function () {
          getBody();
        }, 1000);
      });
    }
  }, []);

  const getBody = async () => {
    var result = selectedAppointment;
    var a = document.getElementById("basic_men_back_single" + id);
    var svgDoc = a.contentDocument;

    for (let i = 0; i < result.length; i++) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = result[i].pathData;
      var el = svgDoc.querySelectorAll('[data-id="' + result[i].id + '"]')[0];
      el.style.fill = result[i].color;
    }
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              <object id={"basic_men_back_single" + id} data={process.env.REACT_APP_SVG_URL + "images/basic_men_back.svg?id=" + id} type="image/svg+xml" height="300"></object>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
