import { ThemeProvider } from "./context/Themes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Error } from "./pages/supports";
import { CRM, ForgotPassword, Login, Register, Onboard, PractitionerSettings, MyAccount, Settings, Subscriptions, Patients, PatientDetails, Appointments, Invoice } from "./pages/master";
import { Provider } from "react-redux";
import { store } from "./store";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 5000,
            style: {
              background: "#000000",
              color: "#fff",
            },
          }}
        />

        <BrowserRouter>
          <Routes>
            {/* master Pages */}

            <Route path="/crm" element={<CRM />} />
            <Route path="/login" element={<Login />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            {/* Supports Pages */}
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/onboard" element={<Onboard />} />
            <Route path="/practitioner-settings" element={<PractitionerSettings />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/patient-details" element={<PatientDetails />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/invoice" element={<Invoice />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}
