import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Settings = () => {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const [Saving, setSaving] = useState(false);
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDescrption, setSiteDescrption] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [about, setAbout] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [terms, setTerms] = useState("");
  const [travelProfessionals, setTravelProfessionals] = useState("");

  useEffect(() => {
    fetchSettings();
  }, []);

  async function fetchSettings() {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/settings", options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setSiteTitle(Response.data.result.siteTitle);
        setSiteDescrption(Response.data.result.siteDescrption);
        setPhone(Response.data.result.phone);
        setEmail(Response.data.result.email);
        setAddress(Response.data.result.address);
        setFacebook(Response.data.result.facebook);
        setInstagram(Response.data.result.instagram);
        setTwitter(Response.data.result.twitter);
        setYoutube(Response.data.result.youtube);
        setSiteTitle(Response.data.result.siteTitle);
        setSiteTitle(Response.data.result.siteTitle);
        setSiteTitle(Response.data.result.siteTitle);
        setAbout(Response.data.result.about);
        setPrivacy(Response.data.result.privacy);
        setTerms(Response.data.result.terms);
        setTravelProfessionals(Response.data.result.travel_professionals);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function updateSettings() {
    if (!siteTitle && siteTitle == "") {
      toast.error("Enter site title");
      return;
    }

    if (!siteDescrption && siteDescrption == "") {
      toast.error("Enter site descrption");
      return;
    }

    if (!phone && phone == "") {
      toast.error("Enter phone number");
      return;
    }

    if (!email && email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }

    if (!address && address == "") {
      toast.error("Enter address");
      return;
    }
    setSaving(true);
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/settings/update",
      {
        siteTitle: siteTitle,
        siteDescrption: siteDescrption,
        phone: phone,
        email: email,
        address: address,
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
        youtube: youtube,
        about: about,
        privacy: privacy,
        terms: terms,
        travel_professionals: travelProfessionals,
      },
      options
    );
    if (Response.data.status == "success") {
      setSaving(false);
      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <Tabs defaultActiveKey="basic">
            <Tab eventKey="basic" title="Basic Information">
              <Row className="mt-3">
                <Col xl={1}></Col>
                <Col xl={10} className="ps-xl-5">
                  <Row>
                    <Col xl={12}>
                      <LabelField label="site title" type="text" fieldSize="w-100 h-md" value={siteTitle} onChange={(e) => setSiteTitle(e.target.value)} />
                    </Col>
                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> Description</label>
                        <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={siteDescrption} onChange={(e) => setSiteDescrption(e.target.value)}></textarea>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <LabelField label="phone number" type="text" fieldSize="w-100 h-md" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Col>
                    <Col xl={6}>
                      <LabelField label="email address" type="email" fieldSize="w-100 h-md" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> address</label>
                        <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                      </div>
                    </Col>

                    <Col xl={12}>
                      <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => updateSettings()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>save all changes</>}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xl={1}></Col>
              </Row>
            </Tab>
            <Tab eventKey="social" title="Social">
              <Row className="mt-3">
                <Col xl={1}></Col>
                <Col xl={10} className="ps-xl-5">
                  <Row>
                    <Col xl={6}>
                      <LabelField label="facebook" type="url" fieldSize="w-100 h-md" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                    </Col>
                    <Col xl={6}>
                      <LabelField label="instagram" type="url" fieldSize="w-100 h-md" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                    </Col>

                    <Col xl={6}>
                      <LabelField label="twitter" type="url" fieldSize="w-100 h-md" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                    </Col>

                    <Col xl={6}>
                      <LabelField label="youtube" type="url" fieldSize="w-100 h-md" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
                    </Col>

                    <Col xl={12}>
                      <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => updateSettings()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>save all changes</>}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xl={1}></Col>
              </Row>
            </Tab>
            <Tab eventKey="cms" title="CMS">
              <Row className="mt-3">
                <Col xl={1}></Col>
                <Col xl={10} className="ps-xl-5">
                  <Row>
                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> About</label>

                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["font"],
                              ["fontSize", "formatBlock"],
                              ["align", "horizontalRule", "lineHeight", "list"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["blockquote", "outdent", "indent"],
                              ["table"],
                              ["link", "video"],
                              ["image"],
                              ["codeView", "fullScreen", "showBlocks"],
                            ],
                          }}
                          setContents={about}
                          height="150px"
                          onChange={setAbout}
                        />
                      </div>
                    </Col>

                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> Terms</label>

                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["font"],
                              ["fontSize", "formatBlock"],
                              ["align", "horizontalRule", "lineHeight", "list"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["blockquote", "outdent", "indent"],
                              ["table"],
                              ["link", "video"],
                              ["image"],
                              ["codeView", "fullScreen", "showBlocks"],
                            ],
                          }}
                          setContents={terms}
                          height="150px"
                          onChange={setTerms}
                        />
                      </div>
                    </Col>

                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> Privacy</label>

                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["font"],
                              ["fontSize", "formatBlock"],
                              ["align", "horizontalRule", "lineHeight", "list"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["blockquote", "outdent", "indent"],
                              ["table"],
                              ["link", "video"],
                              ["image"],
                              ["codeView", "fullScreen", "showBlocks"],
                            ],
                          }}
                          setContents={privacy}
                          height="150px"
                          onChange={setPrivacy}
                        />
                      </div>
                    </Col>

                    <Col xl={12}>
                      <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => updateSettings()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>save all changes</>}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xl={1}></Col>
              </Row>
            </Tab>
          </Tabs>
        </Box>
      </CardLayout>
    </PageLayout>
  );
};

export default Settings;
