const initialState = {
  counter: 0,
  userData: {},
  loginStatus: false,
  token: "",
  refreshToken: "",
  saveIndicator: false,
  reportData: {
    openModal: false,
    selectedAppointment: "",
    initial: false,
    male: false,
    tab: "",
  },
  chat: {
    openModal: false,
    selectedAppointment: "",
    segment: "",
  },
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INCREMENT_COUNTER":
      return {
        ...state,
        counter: action.payload,
      };
    case "DECREMENT_COUNTER":
      return {
        ...state,
        counter: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "SET_LOGIN_STATUS":
      return {
        ...state,
        loginStatus: action.payload,
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };

    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: action.payload,
      };

    case "SET_SAVE_INDICATOR":
      return {
        ...state,
        saveIndicator: action.payload,
      };
    case "SET_REPORT_DATA":
      return {
        ...state,
        reportData: action.payload,
      };

    case "SET_CHAT_DATA":
      return {
        ...state,
        chat: action.payload,
      };

    default:
      return state;
  }
};

export default counterReducer;
