import React, { useContext, useState } from "react";
import { WidgetDropdown, ProfileDropdown } from "../components/header";
import { Button, Section, Box } from "../components/elements";
import { DrawerContext } from "../context/Drawer";
import { ThemeContext } from "../context/Themes";
import { Logo } from "../components";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useEffect } from "react";
import data from "../data/master/header.json";
import { setRefreshToken } from "../actions";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, token, counter } = useSelector((state) => state.counter);
  const { drawer, toggleDrawer } = useContext(DrawerContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [scroll, setScroll] = useState("fixed");

  const [count, setCount] = useState("0");

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) setScroll("sticky");
    else setScroll("fixed");
  });

  return (
    <Section as="header" className={`mc-header text-white ${scroll}`}>
      <Logo src={data?.logo.src} alt={data?.logo.alt} name={"   "} href={"/crm"} />
      <Box className="mc-header-group">
        <Box className="mc-header-left">
          <Button icon={drawer ? "menu_open" : "menu"} className="mc-header-icon toggle" onClick={toggleDrawer} />
        </Box>
        <Box className="mc-header-right">
          <ProfileDropdown name={userData.fname + " " + userData.lname} image={data.profile.image} username={userData.fname + " " + userData.lname} dropdown={data.profile.dropdown} />
        </Box>
      </Box>
    </Section>
  );
}
