import React, { useContext } from "react";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import { Logout } from "../components/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Sidebar() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const { drawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Section as="aside" className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}>
      <ul class="mc-sidebar-menu-list">
        <li className={location.pathname == "/crm" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/crm" onClick={() => navigate("/crm")}>
            <i class="material-icons">dashboard</i>
            <span>Dashboard</span>
          </a>
        </li>

        {userData && userData.type == 0 && (
          <>
            <li className={location.pathname == "/subscriptions" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/subscriptions " onClick={() => navigate("/subscriptions")}>
                <i class="material-icons">money</i>
                <span>Subscriptions </span>
              </a>
            </li>
            <li className={location.pathname == "/practitioners" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/practitioners" onClick={() => navigate("/practitioners")}>
                <i class="material-icons">group</i>
                <span>Practitioners </span>
              </a>
            </li>
            <li className={location.pathname == "/subscriptions " ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/subscriptions " onClick={() => navigate("/settings")}>
                <i class="material-icons">analytics</i>
                <span>Reports </span>
              </a>
            </li>
            <li className={location.pathname == "/settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/settings" onClick={() => navigate("/settings")}>
                <i class="material-icons">settings</i>
                <span>Settings</span>
              </a>
            </li>
          </>
        )}

        {userData && userData.type == 1 && (
          <>
            <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/patients" onClick={() => navigate("/patients")}>
                <i class="material-icons">group</i>
                <span>Manage patient</span>
              </a>
            </li>
            <li className={location.pathname == "/appointments" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/appointments" onClick={() => navigate("/appointments")}>
                <i class="material-icons">event</i>
                <span>Manage appointment</span>
              </a>
            </li>
            <li className={location.pathname == "/practitioner-settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/practitioner-settings" onClick={() => navigate("/practitioner-settings")}>
                <i class="material-icons">settings</i>
                <span>Settings</span>
              </a>
            </li>

            <li className={location.pathname == "/invoice" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/invoice" onClick={() => navigate("/invoice")}>
                <i class="material-icons">money</i>
                <span>Subscription & invoice</span>
              </a>
            </li>
          </>
        )}
      </ul>
      <Logout />
    </Section>
  );
}
